<template>
  <b-modal
      id="modal-contents-data"
      @hide="$emit('close-modal-contents-data')"
      size="lg"
      ok-only
      :ok-title="$t('forms.close')"
      :title="content.title"

  >
  <b-row v-if="loading">
      <b-col cols="12"  >
        <div class="text-center">
          <b-spinner label="Loading..." />
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col
        lg="8"
        cols="12"
      >
        <b-card>
         
          <div
            class="d-flex justify-content-start align-items-center mb-1"
          >
            <!-- avatar -->
            <b-avatar
            rounded
            variant="light"
              :src="content.icon"
              size="50"
              class="mr-1"
            />
            <!--/ avatar -->
            <div class="profile-user-info">
              <h6 class="mb-0">
                {{ content.title }}
              </h6>
              <small class="text-muted">{{ $t('content_types.'+content.type) }}</small>
            </div>
          </div>
          <b-card-text>
            {{ content.description }}
          </b-card-text>

      
          <template v-if="content.url">
            <div class="position-relative">
              <template>
                <div ref="fullscreenContainer" class="position-relative">
                  <img 
                    v-if="isFullscreen"
                    :src="require('@/assets/images/elements/MinimizeIcon.png')"
                    class="fullscreen-icon cursor-pointer " 
                    @click="exitFullscreen"
                  />
                  <b-embed
                    id="webglplayer"
                    type="iframe"
                    :src="resolveContentUrl(content)"
                    allowfullscreen
                    class="rounded mb-50"
                  />
                </div>
              </template>
              <img 
                v-if="!isFullscreen && content.type !== 'pdf' && content.type !== 'video' && content.type !== 'customaudiobook'"
                :src="require('@/assets/images/elements/MaximizeIcon.png')"
                class="fullscreen-icon cursor-pointer" 
                @click="makeIframeFullscreen"
              />
              </div>
            <div v-if="content.type == 'pdf' || content.type == 'video' || content.type == 'customaudiobook'|| content.type == 'customquiz'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="mt-25 d-flex flex-column align-items-center"
            >
            <br>
              <h5 class="mb-2">{{ $t('learning_path.preview')}}</h5>
              <div>
                <b-badge v-for="(lang, idx) in Object.keys(content.url)" :key="idx"
                  :variant="lang === prev_lang ? 'secondary' : 'primary'"
                  class="mr-25 cursor-pointer"
                  @click="prev_lang=lang"
                >
                  {{lang.toUpperCase()}}
                </b-badge>
              </div>
            </div>
          </template>
        </b-card>
      </b-col>
      <b-col
        lg="4"
        cols="12"
      >
      <b-card>
         <!-- <b-img
            v-if="content.thumbnail_url"
            fluid
            rounded
            class="mb-25"
            :src="content.thumbnail_url"
          /> -->
        <b-card-text>
            <span class="text-center" style="width:40px; margin-right:10px;">
                 <img height="30" :src="content.intelligences[0].icon"> 
            </span>
             <span>{{content.intelligences[0].name.replaceAll('{0}', 'Smile and Learn')}}</span>
       </b-card-text>
        <b-card-text v-if="content.min_age && content.max_age">
            <feather-icon size="30" class="mr-1" icon="SmileIcon" />
            <span>{{content.min_age}} - {{content.max_age}} {{ $t('learning_path.years_old') }}</span>
        </b-card-text>
        <b-card-text v-if="content.levels.length >0">
            <feather-icon size="30" class="mr-1" icon="BarChartIcon" />
            <span v-for="(l, index) in content.levels" :key="index">{{$t('apps_levels.'+l)}}<span v-if="index != content.levels.length - 1">, </span></span>
        </b-card-text>

         <template v-if="content.languages.length >0">
          <h5 class="text-capitalize mb-75">
            {{ $t('learning_path.language') }}
          </h5>
          <b-card-text>
            <b-badge
              v-for="language in content.languages" :key="language"
              pill
              variant="primary"
              class="mr-25"
            >
              {{ language.toUpperCase() }}
            </b-badge>
          </b-card-text>
        </template>

        <template v-if="content.academic_areas.length >0">
          <h5 class="text-capitalize mb-75">
            {{ $t('learning_path.academic_areas') }}
          </h5>
          <b-card-text>
            <b-badge
              v-for="aa in content.academic_areas" :key="aa.id"
              pill
              variant="primary"
              class="mr-25"
            >
              {{ aa.name }}
            </b-badge>
          </b-card-text>
        </template>
        
        <template v-if="content.academic_subareas.length >0">
          <h5 class="text-capitalize mb-75">
            {{ $t('learning_path.academic_subareas') }}
          </h5>
          <b-card-text>
            <b-badge
              v-for="acs in content.academic_subareas" :key="acs.id"
              pill
              variant="primary"
              class="mr-25"
            >
              {{ acs.name }}
            </b-badge>
          </b-card-text>
        </template>
       
        <template v-if="content.cognitive_skills.length >0">
          <h5 class="text-capitalize mb-75">
            {{ $t('learning_path.cognitive_skills') }}
          </h5>
          <b-card-text>
            <b-badge
              v-for="csk in content.cognitive_skills" :key="csk.id"
              pill
              variant="primary"
              class="mr-25 mb-05"
            >
              {{ csk.name }}
            </b-badge>
          </b-card-text>
        </template>

        <template v-if="content.competences.length >0">
          <h5 class="text-capitalize mb-75">
            {{ $t('learning_path.competences') }}
          </h5>
          <b-card-text>
            <b-badge
              v-for="comp in content.competences" :key="comp.id"
              pill
              variant="primary"
              class="mr-25 mb-05 custom-badge"
            >
              {{ comp.name }}
            </b-badge>
          </b-card-text>
        </template> 

       
      </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BAvatar, BAvatarGroup, BFormTextarea, BFormGroup, BEmbed, BSpinner,
  BBadge, BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { fetchContentDetails } from '@/api/learningpaths'

export default {
components:{
    BAvatar, BAvatarGroup, BFormTextarea, BFormGroup, BEmbed, BSpinner,
    BBadge, BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props:{
    selected_data:{
      type:[String, Number],
      required: false
    },
    report_lang:{
      type: String,
      required: false,
      default: null
    },
  },
  data(){
    return{
      loading: true,
      content:{
        title:""
      },
      prev_lang: 'es',
      isFullscreen: false,
    }
  },
  watch:{
    selected_data(){
      if(this.selected_data){
        this.getData(this.selected_data)
      }

    }
  },
  methods:{
    makeIframeFullscreen() {
      let container = this.$refs.fullscreenContainer;
      if (container.requestFullscreen) {
        container.requestFullscreen();
      } else if (container.mozRequestFullScreen) { /* Firefox */
        container.mozRequestFullScreen();
      } else if (container.webkitRequestFullscreen) { /* Chrome, Safari */
        container.webkitRequestFullscreen();
      } else if (container.msRequestFullscreen) { /* IE/Edge */
        container.msRequestFullscreen();
      }
      this.isFullscreen = true;
    },
    exitFullscreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen().catch((error) => {
          console.error("Error attempting to exit fullscreen mode: ", error);
        });
        this.isFullscreen = false;
      }
    },
  resolveContentUrl(content){
    if(content.type == 'pdf' || content.type == 'video' || content.type == 'customaudiobook'|| content.type == 'customquiz'){
      return content.url[this.prev_lang]
    } else {
      return content.url
    }
  },
    async getData(id){
      this.loading = true
      let data = {
          'content_id': id,
          'user_id':  this.$store.getters['user/getUserData'].id,
          'token': this.$store.getters['user/getUserData'].sso,
          'lang': this.report_lang ?? this.$store.getters['user/getLang'],
      }
      await this.$http.post(fetchContentDetails, data).then( response => {
        this.content = response.data
        this.loading = false

      }).catch(error => {
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }




}
</script>

<style>
.custom-badge {
  white-space: normal;
  width: max-content;
  max-width: 25ch;
  overflow-wrap: break-word;
}
.mb-05 {
  margin-bottom: 0.5rem !important;
}
.cursor-pointer {
  cursor: pointer;
}
.webglplayer-container {
  position: relative;
}

.fullscreen-icon {
  width: 5%; 

  position: absolute;
  top: 1%;
  right: 5%;
  z-index: 10;
}
</style>